<template>
    <div
        class="formRadio"
        :class="{checked: checked, disabled: disabled}"
    >

        <svg
            v-if="checked"
            :width="size+'px'"
            :height="size+'px'"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="19" height="19" rx="9.5" fill="white" stroke="#5493A4"/>
            <rect x="3.16699" y="3.16699" width="14.6667" height="14.6667" rx="7.33333" fill="#5493A4"/>
        </svg>

        <svg
            v-if="!checked"
            :width="size+'px'"
            :height="size+'px'"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="19" height="19" rx="9.5" fill="#FBFBFB" stroke="#E1E6EB"/>
        </svg>

    </div>
</template>

<script>
export default {
    name: 'FormRadio',
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: Number,
            defult: 21,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
    .formRadio{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.checked{
            cursor: default;
        }
        &[disabled="true"]{
            opacity: .5;
            cursor: not-allowed;
        }
    }
</style>