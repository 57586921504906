<template>
    <div
        @click="changeSorting()"
        class="sorting-icons"
        :class="{'sorting-icons--right': alignRight}">
        <div class="sorting-icons__icon">
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.26564 1.4883C8.134 1.33251 7.92058 1.33251 7.78895 1.4883L4.21422 5.71901C4.00101 5.97135 4.15359 6.4027 4.45511 6.40001L11.551 6.40002C11.8501 6.39736 11.9983 5.96931 11.7868 5.71903L8.26564 1.4883Z"
                    :fill="fillUp"
                />
                <path
                    d="M7.73534 14.5117C7.86697 14.6675 8.0804 14.6675 8.21203 14.5117L11.7868 10.281C12 10.0286 11.8474 9.5973 11.5459 9.59999L4.44993 9.59998C4.15087 9.60264 4.00265 10.0307 4.21413 10.281L7.73534 14.5117Z"
                    :fill="fillDown"
                />
            </svg>
        </div>
        <div v-if="label.length > 0" class="sorting-icons__label">
            {{ label }}
        </div>


    </div>
</template>

<script>
    export default {
        name: 'SortingIcons',
        props: {
            initial: {
                type: String,
                default: 'none'
            },
            label: {
                type: String,
                default: ''
            },
            isActive: {
                type: Boolean,
                default: false
            },
            alignRight: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                sorting: this.initial,
            }
        },
        watch: {
            isActive(val, oldVal) {
                if (!val) this.sorting = 'none'
            }
        },
        computed: {
            fillUp() {
                return this.sorting === 'desc' ? '#313131' : '#B1B1B1'
            },
            fillDown() {
                return this.sorting === 'asc' ? '#313131' : '#B1B1B1'
            },
        },
        methods: {
            changeSorting() {
                switch(this.sorting) {
                    case 'asc':
                        this.sorting = 'desc'
                        break;
                    case 'desc':
                    case 'none':
                        this.sorting = 'asc'
                        break;
                }
                this.$emit('sorted', this.sorting)
            },
        },
        beforeMount() {
        }
    };
</script>

<style lang="scss">
    .sorting-icons{
        display: inline-flex;
        flex-direction: row;
        user-select: none;
        cursor: pointer;
        &--right{
            display: flex;
            justify-content: flex-end;
        }
        &__icon{
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
        &__label{
            white-space: nowrap;
        }


    }
</style>