<template>
    <div class="inView"></div>
</template>

<script>

// Компонент отслеживает свое положение на странице
// и отправляет событие inview
// как только появляется в поле видимости

export default {
    name: 'InView',
    props: [],
    data() {
        return {
            observer: false,
        }
    },
    computed: {},
    methods: {
        makeIntersectionObserver() {
            this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.$emit('inview')
                    }
                })
            }, { threshold: 0.5 })
        },
    },
    mounted() {
        this.makeIntersectionObserver();
        this.observer.observe(this.$el)
    }
};
</script>

<style lang="scss">

</style>