<template>
    <div class="FormTextarea">

        <div
            class="FormTextarea__label"
            v-if="label">
            {{ label }}
        </div>

        <textarea
            rows="1"
            spellcheck="false"
            class="form-control"
            @keydown=autosize
            @mouseup=autosize
            @mouseout=autosize
            @keyup.enter=autosize
            @input="updateValue"
            @keypress="autosize"
            :placeholder="(placeholder ? placeholder : 'Enter the message..')">{{ modelValue }}</textarea>
    </div>
</template>

<script>
    module.exports = {
        name: 'FormTextArea',
        props: {
            placeholder: {
                type: String,
                default: ''
            },
            modelValue: {
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
        },
        data() {
            return {}
        },
        model: {
            prop: 'modelValue',
            event: 'update'
        },
        computed: {

        },
        watch: {},
        beforeMount() {
        },
        mounted() {
            this.autosize();
        },
        methods: {
            updateValue(event, oldVal) {
                this.$emit('update:modelValue', event.target.value)
                this.autosize()
            },
            autosize() {
                if (this.$el) {
                    let textarea = this.$el.getElementsByTagName('textarea')[0];
                    textarea.style.cssText = 'height:auto; padding:0';
                    textarea.style.cssText = '-moz-box-sizing:content-box';
                    textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px';
                } else {
                    setTimeout(this.autosize, 300);
                }
            }
        }
    };
</script>


<style lang="scss">

    @import '../scss/utils/mixins.scss';
    @import '../scss/utils/variables.scss';

    .FormTextarea {
        position: relative;
        &__label{
            position: absolute;
            z-index: 9;
            bottom: calc(100% - 10px);
            left: 5px;
            padding: 0 5px;
            border-radius: 5px;
            background: white;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            font-family: $font-secondary;
            font-weight: 300;
            font-size: 13px;
            line-height: 120%;
            height: 20px;
            letter-spacing: 0.5px;
            color: $color-text-muted;
        }
        textarea {
            &, &:focus, &:hover, &[readonly] {
                width: 100%;
                min-height: 50px;
                font-family: $font-secondary;
                font-size: 14px;
                line-height: 16px;
                padding: 15px 20px;
                border: 1px solid $color-day-line;
                border-radius: 3px;
                letter-spacing: .5px;
                display: block;
                resize: none;
                margin: 0;
                background: transparent;
                transition: .2s all ease;
                color: $color-text-default;
            }
            &.active {
                /*background: white;*/
                /*box-shadow: 0 1px 1px rgba(0, 0, 0, .1);*/
            }
        }
        &__btns {
            margin: 10px 0 10px;
            display: flex;
            flex-direction: row;
            button.sm_editor {
                line-height: 30px;
            }
        }
    }
</style>
