<template>
    <div
        v-if="all > visible"
        class="ShowMore">
        <div class="ShowMore__label">
            {{ $lng.__('lk_webmaster_main', 'Showing %s of %s items').replace('%s', visible).replace('%s', all) }}
        </div>
        <button
            @click="showMore()"
            class="wv-btn--blue wv-btn--text">
            {{ $lng.__('lk_webmaster_main', 'Show more') }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'ShowMore',
    props: {
        visible: {
            required: false,
            type: Number,
            default: 15,
        },
        all: {
            required: true,
            type: Number,
            default: 15,
        }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        showMore() {
            this.$emit('more')
        },
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">

    @import '../scss/utils/mixins.scss';
    @import '../scss/utils/variables.scss';

    .ShowMore{
        width: 100%;
        padding: 20px 30px;
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        &__label{
            font-family: $font-secondary;
            font-size: 16px;
            font-weight: normal;
            color: $color-day-gray;
            span{
                font-weight: 600;
            }
        }
    }
</style>