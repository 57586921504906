<template>
    <div
        class="loading"
        :class="addclass">

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          class="circular"
          viewBox="25 25 50 50">
            <circle
              class="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke-width="3"
              stroke-miterlimit="10">
            </circle>
        </svg>

        <!-- <div class="loading__message">Загрузка</div> -->

    </div>
</template>

<script>
    export default {
        name: 'Loading',
        props: ['addclass'],
    }
</script>

<style scoped lang="scss">
    .loading {
        flex: none;
        position: relative;
        margin: 20px auto;
        width: 50px;
        padding: 20px 0 30px;
        &__message{
            text-align: center;
            padding: 30px 0 0 !important;
            margin-top: 10px;
            font-size: 16px;
            display: flex;
            color: #757575;
            align-items: center;
            justify-content: center;
        }
    }

    .loading:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .circular {
        -webkit-animation: rotate 1.5s linear infinite;
        animation: rotate 1.5s linear infinite;
        height: 100%;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .path {
        stroke-dasharray: 1, 250;
        stroke-dashoffset: 0;
        -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
    }


    @-webkit-keyframes
    rotate {  100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }

    @keyframes
    rotate {  100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }

    @-webkit-keyframes
    dash {  0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
        }
    }

    @keyframes
    dash {  0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
        }
    }

    @-webkit-keyframes
    color {100%, 0% {stroke: #2ddab3;}
        40% {stroke: #2ddab3;}
        66% {stroke: #2ddab3;}
        80%, 90% {stroke: #2ddab3;}
    }

    @keyframes
    color {100%, 0% {stroke: #2ddab3;}
        40% {stroke: #2ddab3;}
        66% {stroke: #2ddab3;}
        80%, 90% {stroke: #2ddab3;}
    }
</style>
