export default {
  isEmailValid: (email) => {
    let isEmailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    if (!isEmailValid) {
      return false;
    }
    return true;
  },
  isPhoneValid: (phone) => {
    let cleanPhone = phone.replace(/\D+/g, '');
    let isPhoneValid = /^((\+7|7|8)+([0-9]){10})$/.test(cleanPhone);
    if (!isPhoneValid) {
      return false;
    }
    return true;
  },
  isOnlyLettersAndNumbers: (value) => {
    return /^[0-9a-zA-Z]+$/.test(value);
  },
  isStringNotEmpty: (string) => {
    if (string === null) return false;
    return String(string).trim().length >= 1;
  },
  isNumberSmallThan: (number, max) => {
    return parseInt(number) < parseInt(max);
  },
  isStringLengthSmallThan: (string, max) => {
    if (string === null) return true;
    return string.length < parseInt(max);
  },
  isStringLengthBiggerThan: (string, min) => {
    if (string === null) return false;
    return string.length > parseInt(min);
  },
  isNumberValid: (val) => {
    return !isNaN(val)
  },
  isDomainNameValid: (domain) => {
    if (domain.length === 0) {
      return true;
    } else {
      return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain);
    }
  },

};
